import PropTypes from "prop-types";
import StickyBox from "react-sticky-box";
import { Link } from "../../../components/al_components/ALLink";
import ArrowIcon from "../../../components/icons/svgs/arrow.svg";
import { isBrowser } from "../../../context/helpers";
import * as Styles from "./sideMenu/styles.module.css";

function scrollToSection(sectionId) {
  if (isBrowser) {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  }
}

function MenuLinkWithArrow({ title, url, hasArrow }) {
  if (url?.includes("#")) {
    return (
      <button
        type="button"
        onClick={() => scrollToSection(url)}
        className={`${Styles.menu__link} ${hasArrow ? "" : Styles.menu__link_active}`}
      >
        <p className="al_p">{title}</p>
        {hasArrow && <ArrowIcon width="7" height="13" />}
      </button>
    );
  }

  return (
    <Link to={url} className={`${Styles.menu__link} ${hasArrow ? "" : Styles.menu__link_active}`}>
      <p className="al_p">{title}</p>
      {hasArrow && <ArrowIcon width="7" height="13" />}
    </Link>
  );
}
MenuLinkWithArrow.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  hasArrow: PropTypes.bool.isRequired,
};

function SideMenu({ menuItems }) {
  if (!menuItems) {
    return null;
  }
  return (
    <div className={Styles.menu}>
      <StickyBox offsetTop={85} offsetBottom={20}>
        {menuItems.map((item) => {
          if (item.isActive) {
            return (
              <div key={item.title}>
                <MenuLinkWithArrow title={item.title} url={item.url} hasArrow={false} />
                {item.subMenuItems && (
                  <div className={Styles.menu__subMenu}>
                    {item.subMenuItems.map((subMenu) => (
                      <MenuLinkWithArrow
                        title={subMenu.title}
                        url={subMenu.url}
                        hasArrow={false}
                        key={subMenu.url}
                      />
                    ))}
                  </div>
                )}
              </div>
            );
          }
          return <MenuLinkWithArrow title={item.title} url={item.url} hasArrow key={item.url} />;
        })}
      </StickyBox>
    </div>
  );
}

SideMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      subMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }).isRequired
  ).isRequired,
};

export default SideMenu;
